import React from "react";
import './About.scss'
import github from "../../assets/github.png"
import twitter from "../../assets/twitter.png"
import linkedin from "../../assets/linkedin.png"

export const About = () => {
    return (

        <div className={"Container"}>
        {/*<div className={"BackgroundImage"}></div>*/}
        <div className={"About"}>
            <div className={"FullName"}>
                <div className="typewriter">
                    <div className="typewriter-text">Noubair Matah</div>
                </div>
            </div>
            <div className={"SocialNetworks floating"}>
                <a target="_blank" href="https://github.com/noubair">
                    <img src={github} className="GithubLogo" alt="github"/>
                </a>
                <a target="_blank" href="https://twitter.com/NoubairMatah">
                    <img src={twitter} className="TwitterLogo" alt="twitter"/>
                </a>

                <a target="_blank" href="https://www.linkedin.com/in/noubair-matah/">
                        <img src={linkedin} className="LinkedinLogo" alt="linkedin"/>
                    </a>
                </div>
        </div>
        </div>
    )
}