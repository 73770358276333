import React from 'react';
import './App.scss';
import {Wrapper} from "./components/Wrapper/Wrapper";

function App() {
  return (
    <div className="App">
        <Wrapper/>
    </div>

  );
}

export default App;
