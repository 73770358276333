import React from "react";
import {Header} from "../Header/Header";
import {About} from "../About/About";
import './Wrapper.scss';


export const Wrapper = () => {
    return(
        <div className={"Wrapper"}>
            {/*<Header/>*/}
            <About/>
        </div>
    )
}